<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('Campaign template') }}</h3>
      </v-col>
    </v-row>
    <v-form v-model="validForm">
      <v-row>
        <v-col lg="6">
          <v-select
            v-model="selectedTemplate"
            :items="organisationTemplates"
            :label="$t('Campaign template')"
            filled
            required
            :rules="$rules.required"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style lang="sass" scoped></style>
<script>
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data: () => ({
    validForm: false
  }),
  computed: {
    organisationTemplates: {
      get() {
        let templates = this.$store.getters['campaigns/template']
        let selections = templates.map(t => {
          return { text: t.name, value: t.id }
        })
        return selections
      }
    },
    selectedTemplate: {
      get() {
        return this.$store.state.campaigns.selectedTemplateId
      },
      set(value) {
        this.$store.commit('campaigns/set', ['selectedTemplateId', value])
      }
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    }
  }
}
</script>
