<template>
  <div>
    <v-container>
      <v-row class="mt-4 mb-2">
        <v-col class="d-md-none"></v-col>
        <v-col
          sm="12"
          order="last"
          md="4"
          order-md="first"
          class="d-flex align-center"
        >
          <span class="headline">
            {{ $t('Timeline') }}
          </span>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <DashboardTabs></DashboardTabs>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <NewCampaignButton></NewCampaignButton>
        </v-col>
      </v-row>

      <v-card class="elevation-4">
        <v-card-text>
          <TimelineElement></TimelineElement>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import DashboardTabs from './components/DashboardTabs'
import NewCampaignButton from './components/NewCampaignButton'
import TimelineElement from './components/timelineElement'

export default {
  components: {
    DashboardTabs,
    NewCampaignButton,
    TimelineElement
  }
}
</script>
