<template>
  <div>
    <v-btn color="primary" large @click="openDialog">
      {{ $t('New campaign') }}
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('Create new campaign') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <EditorBasic in-modal @is-valid="basicValidHandler"></EditorBasic>
            <TemplateSelector
              @is-valid="templateValidHandler"
            ></TemplateSelector>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!isValid"
            @click="createCampaign"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EditorBasic from '../../campaign/editor/Basic'
import TemplateSelector from '../../campaign/editor/TemplateSelector'
export default {
  components: {
    EditorBasic,
    TemplateSelector
  },
  data: () => ({
    dialog: false,
    basicIsValid: false,
    templateIsValid: false
  }),
  computed: {
    isValid: {
      get() {
        return this.basicIsValid === true && this.templateIsValid === true
      }
    }
  },
  methods: {
    openDialog() {
      let me = this
      me.$store.commit(
        'campaigns/newCampaignToEditor',
        me.$store.getters['organisation/id']
      )
      me.dialog = true
    },
    basicValidHandler(value) {
      this.basicIsValid = value
    },
    templateValidHandler(value) {
      this.templateIsValid = value
    },
    async createCampaign() {
      let me = this
      try {
        let campaign = await me.$store.dispatch('campaigns/createCampaign')
        me.$toast({
          type: 'success',
          message: `New campaign "${campaign.name}" created`
        })
        await me.$redirect({
          name: 'campaign-editor',
          params: { campaignId: campaign.id }
        })
      } catch (err) {
        me.$error(err)
      }
    }
  }
}
</script>
