<template>
  <body class="pa-2">
    <div class="d-flex flex-row pb-4">
      <v-checkbox v-model="Ongoing" class="mx-2" label="Ongoing"></v-checkbox>
      <v-checkbox v-model="Draft" class="mx-7" label="Draft"></v-checkbox>
      <v-checkbox v-model="Past" class="mx-7" label="Past"></v-checkbox>
    </div>
    <div id="app">
      <timeline
        ref="timeline"
        :items="items"
        :groups="groups"
        :options="options"
        @select="openCampaignEditor"
      ></timeline>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </body>
</template>

<style lang="sass" scoped>
@import '@/scss/variables.scss'

.vis-timeline
  border: 2px solid purple
  font-family: purisa, "comic sans", cursive
  font-size: 12pt
  background: #ffecea

vis-label
  padding: 50px
  background: blue
</style>

<script>
//import _ from 'lodash'
import moment from 'moment'
export default {
  data() {
    return {
      Ongoing: true,
      Draft: true,
      Past: true,
      options: {
        start: moment()
          .subtract(7, 'd')
          .format('YYYY-MM-DD'),
        end: moment()
          .add(7, 'd')
          .format('YYYY-MM-DD'),
        groupOrder: function(a, b) {
          return a.value - b.value
        },
        locale: 'de', // TODO: figure this out
        orientation: 'top',
        stack: true,
        selectable: true
      },
      loading: false,
      colorIndex: 0
    }
  },

  computed: {
    items: {
      get() {
        let allCampaigns = this.$store.getters['campaigns/campaigns']
        let campaigns = []
        if (this.Ongoing) {
          campaigns = allCampaigns.filter(c => c.suite_state === 3)
          campaigns = this.campaignsForTimeline(campaigns, true, true)

          let startedCampaigns = allCampaigns.filter(
            c => c.state === 'started' && c.suite_state !== 3
          )
          startedCampaigns = this.campaignsForTimeline(
            startedCampaigns,
            true,
            false
          )

          campaigns = campaigns.concat(startedCampaigns)

          let publishedCampaigns = allCampaigns.filter(
            c =>
              (c.state === 'published' || c.state === 'stopped') &&
              c.suite_state !== 3
          )
          publishedCampaigns = this.campaignsForTimeline(
            publishedCampaigns,
            false,
            false
          )
          campaigns = campaigns.concat(publishedCampaigns)
        }
        if (this.Draft) {
          let drafts = allCampaigns.filter(
            c => c.state === 'draft' && c.suite_state !== 3
          )
          drafts = this.campaignsForTimeline(drafts, false)
          campaigns = campaigns.concat(drafts)
        }
        if (this.Past) {
          let pasts = allCampaigns.filter(
            c => c.state === 'ended' && c.suite_state !== 3
          )
          pasts = this.campaignsForTimeline(pasts, false)
          campaigns = campaigns.concat(pasts)
        }
        this.$debug('consolecampaigns ' + campaigns)
        this.colorIndexToZero()
        return campaigns
      }
    },
    groups: {
      get() {
        return this.channelsForTimeline(
          this.$store.getters['organisation/channels']
        )
      }
    }
  },

  methods: {
    getDate() {
      return (
        Date.prototype.getFullYear() +
        '-' +
        Date.prototype.getMonth() +
        '-' +
        Date.prototype.getDate()
      )
    },
    colorIndexToZero() {
      this.colorIndex = 0
    },
    async fetchChannels() {
      return await this.$store.getters['organisation/channelsForTimeline']
    },
    async fetchCampaigns() {
      return await this.$store.getters[
        'campaigns/publishedCampaignsForTimeline'
      ]
    },
    campaignsForTimeline(campaigns, isOngoing, isLive) {
      let dataTimelineNeeds = []
      //let me = this
      //go trough all campaigns
      campaigns.forEach(element => {
        // My little color generator ---
        let colors = ['#4eb0cc4d', '#92b63d4d', '#bc29654d', '#fadd4a4d']
        let color = colors[this.colorIndex % 4]
        this.colorIndex++
        // My little color generator --- END ---

        if (element.start_date) {
          // We go trough the channels any campaign has, and duplicate the campaign object for those channels.
          // (this is a Vis.JS requirement, it's the extension used to render the Timeline)
          for (let channel in element.channels) {
            dataTimelineNeeds.push({
              id: element.id + channel,
              group: element.channels[channel],
              start: element.start_date,
              end: element.end_date,
              content: isOngoing
                ? isLive
                  ? '<div style="padding: 0 15px;">' +
                    element.client +
                    ', ' +
                    element.name +
                    ' <div class="chip" style="display:' +
                    ' inline-block; padding: 0 15px; height: 24px; line-height: 24px; border-radius: 25px;' +
                    ' background-color: #14D684; color: white; font-weight: bold;" > LIVE </div></div>'
                  : '<div style="padding: 0 15px;">' +
                    element.client +
                    ', ' +
                    element.name
                : '<div style="padding: 0 15px; color: #37424a66; ">' +
                  element.client +
                  ', ' +
                  element.name,

              style:
                'border-radius: 25px; border-style: hidden; background-color: ' +
                color +
                ';'
            })
          }
        }
      })
      return dataTimelineNeeds
    },
    async openCampaignEditor(event) {
      let me = this
      await me.$redirect({
        name: 'campaign-editor',
        params: {
          campaignId: event.items[0].slice(0, -1),
          pageOfOrigin: 'timeline'
        }
      })
    },

    channelsForTimeline(channels) {
      let dataTimelineNeeds = []
      channels.forEach(element => {
        dataTimelineNeeds.push({
          id: element.id,
          content:
            '<div style="padding: 30px;"><img src=' +
            element.logo_url +
            ' style="max-width:144px; max-height:72px;"> <div>' +
            element.name +
            '</div><div>',
          style: 'text-align: center;'
        })
      })
      return dataTimelineNeeds
    }
  }
}
</script>
